<!-- eslint-disable vue/no-template-shadow -->
<template>
  <!--<div>
    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('user:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            v-if="currentTab === 0"
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="changePerPage()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-tabs
      v-if="!isLoading"
      v-model="currentTab"
    >
      <v-tab
        v-if="
          permisos.includes('user:list') ||
            permisos.includes('*')
        "
        @click="setCantUser(-1)"
      >
        {{ $t('menu.usersPropio') }}
      </v-tab>
      <fragment
        v-if="
          permisos.includes('users_son:list') ||
            permisos.includes('*')
        "
      >
        <v-tab
          v-if="user.nivel === -1"
          @click="setCantUser(4)"
        >
          {{ $t('menu.usersAdmin') }}
        </v-tab>
        <v-tab
          v-if="user.nivel === -1 || user.nivel === 0"
          @click="setCantUser(1)"
        >
          {{ $t('menu.userRegister') }}
        </v-tab>
        <v-tab
          v-if="user.nivel === 1 && user.is_agencia"
          @click="setCantUser(2)"
        >
          {{ $t('menu.agentes') }}
        </v-tab>
      </fragment>
    </v-tabs>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.name') }}</strong>
          <v-text-field
            v-model="searchUsers.name"
            class="pt-2"
            :label="$t('lbl.name')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.company') }}</strong>
          <v-autocomplete
            v-model="searchUsers.company"
            class="pt-2"
            :items="itemsCompany"
            :search-input.sync="searchCompany"
            hide-details
            hide-selected
            :label="$t('lbl.company')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
            single-line
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.company') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('menu.roles') }}</strong>
          <v-select
            v-model="searchUsers.rol"
            class="pt-2"
            :items="roles"
            item-text="name"
            item-value="id"
            :label="$t('menu.roles')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.states') }}</strong>
          <v-select
            v-model="searchUsers.active"
            class="pt-2"
            :items="estados"
            item-text="name"
            item-value="id"
            :label="$t('lbl.states')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="searchUsers.name || searchUsers.company > 0 || searchUsers.rol > 0 || searchUsers.active < 2"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="load()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          {{ totalUsersFilters }} {{ $t('lbl.de') }} {{ totalUsersAll }} {{ $t('menu.users') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-if="!reseting">
        <UsersTab v-if="cargarPropios" />
      </v-tab-item>
      <v-tab-item v-if="user.nivel === -1 && !reseting">
        <UsersAdminTab v-if="cargarAdmin" />
      </v-tab-item>
      <v-tab-item v-if="(user.nivel === -1 || user.nivel === 0) && !reseting">
        <UsersAfiliadosTab v-if="cargarAfiliados" />
      </v-tab-item>
      <v-tab-item v-if="user.nivel === 1 && user.is_agencia && !reseting">
        <UsersAgentesTab v-if="cargarAgentes" />
      </v-tab-item>
    </v-tabs-items>
  </div>-->
  <div id="user-list">
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { n: item.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- RESET PASSWORD -->
    <v-dialog
      v-model="isDialogVisibleResetPassword"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>
          {{ $t('btn.changePassword') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.resetPasswordByAdmin') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleResetPassword = !isDialogVisibleResetPassword"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmResetPassword()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- app drawer
      @refetch-data="fetchUsers"
    -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
    ></user-list-add-new>

    <!-- user total card
    <v-row class="mb-5">
      <v-col
        v-for="total in userTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>-->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t('lbl.filtros') }}
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="searchUsers.rol"
            class="pt-2"
            :items="roles"
            item-text="name"
            item-value="id"
            :label="$t('menu.roles')"
            outlined
            clearable
            dense
            hide-details
            @change="getUsers()"
          ></v-select>
        </v-col>

        <!-- plan filter -->
        <v-col
          v-if="user.nivel <= 0"
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="searchUsers.company"
            class="pt-2"
            :items="itemsCompany"
            :search-input.sync="searchCompany"
            hide-details
            hide-selected
            :label="$t('dashboard.companyConfiguration')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
            clearable
            @change="getUsers()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('dashboard.companyConfiguration') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- status filter
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>-->
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="searchUsers.name"
            :label="$t('btn.search')"
            outlined
            hide-details
            dense
            @input="onPageChange()"
          >
          </v-text-field>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="onPageChange()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            v-if="permisos.includes('user:create')"
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
          <!--<v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New User</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>-->
        </v-col>
      </v-card-text>

      <!-- table
        :options.sync="options"
        show-select
      -->

      <!--<v-data-table
        v-model="items"
        :headers="tableColumns"
        :items="items"
        :server-items-length="items.length"
        item-key="name"
        :loading="loading"
        hide-default-footer
      >
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="rutaPublic + item.avatar"
              ></v-img>
              <v-img
                v-else
                src="@/assets/images/avatars/1.png"
              ></v-img>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <a class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                {{ item.name }} {{ item.apellidos }}
              </a>
              <small>{{ item.email }}</small>
            </div>
          </div>
        </template>

        <template #[`item.role`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.role.name }}</span>
          </div>
        </template>

        <template #[`item.company_id`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">
            {{
              item.company_id !== null
                ? item.company.name
                : item.nivel === -1
                  ? 'Soporte'
                  : item.nivel === 0
                    ? 'Propietario'
                    : ''
            }}
          </span>
        </template>

        <template #[`item.active`]="{item}">
          <v-chip
            v-if="item.creado"
            small
            color="error"
            class="v-chip-light-bg font-weight-semibold text-capitalize error--text"
          >
            <span>Creado</span>
          </v-chip>
          <v-chip
            v-else
            small
            :color="item.active ? 'success' : 'error'"
            :class="item.active ? 'success--text' : 'error--text'"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <span v-if="item.active">Activo</span>
            <span v-if="!item.active">Inactivo</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            v-if="
              permisos.includes('user:edit') ||
                permisos.includes('user:list') ||
                permisos.includes('user:destroy') ||
                permisos.includes('*')
            "
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="permisos.includes('user:list')"
                link
                @click="viewItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>{{ $t('btn.see') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="permisos.includes('user:edit')"
                link
                @click="editItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  <span>{{ $t('btn.edit') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  permisos.includes('user:destroy') &&
                    item.email !== 'soporteb2b@wamasol.com' &&
                    item.email !== 'adminb2b@proturs.com' &&
                    item.email !== 'adminb2b@wamasol.com' &&
                    item.email !== 'adminb2b@travelzun.com' &&
                    item.email !== 'adminb2b@kronos.com' &&
                    user.email !== item.email
                "
                link
                @click="deleteItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('btn.delete') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table> -->

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                USER
              </th>
              <th>
                EMAIL
              </th>
              <th>
                ROLE
              </th>
              <th>
                COMPAÑÍA
              </th>
              <th>
                ESTADO
              </th>
              <th
                v-if="
                  permisos.includes('user:edit') ||
                    permisos.includes('user:list') ||
                    permisos.includes('user:destroy') ||
                    permisos.includes('*')
                "
                class="text-uppercase"
              >
                {{ $t('lbl.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(iten, index) in items"
              :key="index"
            >
              <td>
                <div class="d-flex align-center">
                  <v-avatar
                    :color="iten.avatar ? '' : 'primary'"
                    :class="iten.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img
                      v-if="iten.avatar"
                      :src="rutaPublic + iten.avatar"
                    ></v-img>
                    <v-img
                      v-else
                      src="@/assets/images/avatars/1.png"
                    ></v-img>
                  </v-avatar>

                  <div class="d-flex flex-column ms-3">
                    <a class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                      {{ iten.name }} {{ iten.apellidos }}
                    </a>
                    <small>{{ iten.email }}</small>
                  </div>
                </div>
              </td>
              <td>{{ iten.email }}</td>
              <td>
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{ iten.role.name }}</span>
                </div>
              </td>
              <td>
                <span class="text-capitalize font-weight-semibold text--primary">
                  {{
                    iten.company_id !== null
                      ? iten.company.name
                      : iten.nivel === -1
                        ? 'Soporte'
                        : iten.nivel === 0
                          ? 'Propietario'
                          : ''
                  }}
                </span>
              </td>
              <td>
                <v-chip
                  v-if="iten.creado"
                  small
                  color="error"
                  class="v-chip-light-bg font-weight-semibold text-capitalize error--text"
                >
                  <span>Creado</span>
                </v-chip>
                <v-chip
                  v-else
                  small
                  :color="iten.active ? 'success' : 'error'"
                  :class="iten.active ? 'success--text' : 'error--text'"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                >
                  <span v-if="iten.active">Activo</span>
                  <span v-if="!iten.active">Inactivo</span>
                </v-chip>
              </td>
              <td
                v-if="
                  permisos.includes('user:edit') ||
                    permisos.includes('user:list') ||
                    permisos.includes('user:destroy') ||
                    permisos.includes('*')
                "
              >
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="permisos.includes('user:list')"
                      link
                      @click="viewItem(iten)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiEye }}
                        </v-icon>
                        <span>{{ $t('btn.see') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permisos.includes('user:edit') && user.email !== iten.email"
                      link
                      @click="editItem(iten)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiSquareEditOutline }}
                        </v-icon>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permisos.includes('user:edit')"
                      link
                      @click="resetPasswordItem(iten)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          mdi-lock-reset
                        </v-icon>
                        <span>{{ $t('btn.changePassword') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        permisos.includes('user:destroy') &&
                          iten.email !== 'soporteb2b@wamasol.com' &&
                          iten.email !== 'adminb2b@proturs.com' &&
                          iten.email !== 'adminb2b@wamasol.com' &&
                          iten.email !== 'adminb2b@travelzun.com' &&
                          iten.email !== 'adminb2b@kronos.com' &&
                          user.email !== iten.email
                      "
                      link
                      @click="deleteItem(iten)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span>{{ $t('btn.delete') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              total-visible="5"
              @input="onPageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiEye,
} from '@mdi/js'

// import UsersTab from '../tabs/Users.vue'
// import UsersAdminTab from '../tabs/UsersAdmin.vue'
// import UsersAfiliadosTab from '../tabs/UsersAfiliados.vue'
// import UsersAgentesTab from '../tabs/UsersAgentes.vue'

import UserListAddNew from '../form/UserListAddNew.vue'

export default {
  components: {
    UserListAddNew,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      namePlataforma: process.env.VUE_APP_NAME,
      currentTab: 0,
      isLoading: true,
      loading: false,
      reseting: false,
      search: '',
      items: [],
      itemsCompany: [],
      searchCompany: null,
      onlyShow: false,
      companies: [],
      roles: [],
      estados: [],
      pagination: {
        current: 1,
        total: 0,
      },
      cargarPropios: true,
      cargarAdmin: false,
      cargarAfiliados: false,
      cargarAgentes: false,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiEye,
      },
      isAddNewUserSidebarActive: false,
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleActive: false,
      isDialogVisibleResetPassword: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      roleOptions: [
        { title: 'Admin', value: 'admin' },
        { title: 'Author', value: 'author' },
        { title: 'Editor', value: 'editor' },
        { title: 'Maintainer', value: 'maintainer' },
        { title: 'Subscriber', value: 'subscriber' },
      ],

      planOptions: [
        { title: 'Basic', value: 'basic' },
        { title: 'Company', value: 'company' },
        { title: 'Enterprise', value: 'enterprise' },
        { title: 'Standard', value: 'standard' },
      ],

      statusOptions: [
        { title: 'Pending', value: 'pending' },
        { title: 'Active', value: 'active' },
        { title: 'Inactive', value: 'inactive' },
      ],

      tableColumns: [
        { text: 'USER', value: 'name', sortable: false },
        { text: 'EMAIL', value: 'email', sortable: false },
        { text: 'ROLE', value: 'role', sortable: false },
        { text: 'COMPAÑÍA', value: 'company_id', sortable: false },
        { text: 'ESTADO', value: 'active', sortable: false },
        {
          text: 'ACCIONES',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      searchUsers: state => state.app.searchUsers,

      // userItemsPerPage: state => state.app.userItemsPerPage,
      userOnlyShow: state => state.app.userOnlyShow,
      totalUsersFilters: state => state.app.totalUsersFilters,
      totalUsersAll: state => state.app.totalUsersAll,
    }),
  },
  watch: {
    searchCompany(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCompany(val.toLowerCase())
      } else {
        this.itemsCompany = []
      }
    },
  },
  created() {
    this.profile()
    this.fetchDataFromEndpoints()
    this.getUsers()

    this.estados.push({ id: 2, name: 'Todos' })
    this.estados.push({ id: 1, name: 'Activos' })
    this.estados.push({ id: 0, name: 'Desactivos' })

    // this.itemsPerPage = this.userItemsPerPage

    this.itemsCompany.push({ id: 0, name_comercial: 'Todas' })
  },
  methods: {
    ...mapMutations([
      'setListUser',
      'setSearchUsers',
      'setTotalUsersFilters',
      'setTotalUsersAll',
      'setUserItemsPerPage',
      'setUserOnlyShow',
    ]),
    filterCompany(v) {
      // this.itemsCompany = []
      if (v === '') {
        this.itemsCompany = []
      } else {
        this.itemsCompany = this.companies.filter(e => e.name_comercial.toLowerCase())
      }
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }
      if (this.user.nivel === 0) {
        this.itemsCompany.push({ id: -1, name_comercial: 'Propietario' })
      } else if (this.user.nivel === -1) {
        this.itemsCompany.push({ id: -1, name_comercial: 'Propietario' })
        this.itemsCompany.push({ id: -2, name_comercial: 'Soporte' })
      }

      this.isLoading = false

      /* this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          if (this.user.nivel === 0) {
            this.itemsCompany.push({ id: -1, name_comercial: 'Propietario' })
          } else if (this.user.nivel === -1) {
            this.itemsCompany.push({ id: -1, name_comercial: 'Propietario' })
            this.itemsCompany.push({ id: -2, name_comercial: 'Soporte' })
          }
          this.getUsers()
          this.isLoading = false
        }) */
    },
    async fetchDataFromEndpoints() {
      try {
        const [
          resUsers,
          resCompanies,
          resRols,
        ] = await Promise.all([
          this.axios.post('users/list'),
          this.axios
            .post('affiliate/list', { page: 1, per_page: 1000 }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('role?page=1&per_page=1000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // USERS
        this.setListUser(resUsers.data)

        // eslint-disable-next-line no-empty
        if (resCompanies.data.success === false) { } else {
          this.companies.push({ id: 0, name_comercial: 'Todas' })
          resCompanies.data.data.forEach(element => {
            this.companies.push({
              id: element.id,
              name_comercial: element.name_comercial,
            })
          })
        }

        // eslint-disable-next-line no-empty
        if (resRols.data.success === false) { } else {
          this.roles.push({ id: 0, name: 'Todos' })
          resRols.data.data.forEach(element => {
            if (element.slug !== 'super-admin') {
              this.roles.push({
                id: element.id,
                name: element.name,
              })
            }
          })
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    onPageChange() {
      this.getUsers()
    },
    async getUsers() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.searchUsers,

        perfil_slug: localStorage.getItem('perfil'),
      }
      await this.axios
        .post('user/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.setTotalUsersFilters(this.items.length)
          }
        })
    },
    newItem() {
      localStorage.removeItem('user-id')
      localStorage.removeItem('onlyShow')
      this.$router.push({ name: 'user-update' })
    },
    savePermisos() {
      this.loading = true

      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    load() {
      this.reseting = true
      setTimeout(() => {
        this.reseting = false
      }, 50)
    },
    changePerPage() {
      this.setUserItemsPerPage(this.itemsPerPage)
      this.load()
    },
    clearSearch() {
      this.setSearchUsers({
        name: null,
        company: 0,
        rol: 0,
        active: true,
      })
      this.reseting = true
      setTimeout(() => {
        this.reseting = false
      }, 50)
    },
    setCantUser(nivel) {
      if (nivel === -1) {
        this.cargarPropios = true
        this.cargarAdmin = false
        this.cargarAfiliados = false
        this.cargarAgentes = false
      } else if (nivel === 4) {
        this.cargarPropios = false
        this.cargarAdmin = true
        this.cargarAfiliados = false
        this.cargarAgentes = false
      } else if (nivel === 1) {
        this.cargarPropios = false
        this.cargarAdmin = false
        this.cargarAfiliados = true
        this.cargarAgentes = false
      } else if (nivel === 2) {
        this.cargarPropios = false
        this.cargarAdmin = false
        this.cargarAfiliados = false
        this.cargarAgentes = true
      }
    },

    editItem(iten) {
      localStorage.setItem('user-id', iten.id)
      localStorage.removeItem('onlyShow')
      this.setUserOnlyShow(false)
      this.$router.push({ name: 'user-update' })
    },
    viewItem(iten) {
      localStorage.setItem('user-id', iten.id)
      localStorage.setItem('onlyShow', true)
      this.setUserOnlyShow(true)
      this.$router.push({ name: 'user-update' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`user/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.getUsers()
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    resetPasswordItem(iten) {
      this.isDialogVisibleResetPassword = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmResetPassword() {
      this.axios
        .post('user/reset-password-by-admin', { id: this.itemId }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.isDialogVisibleResetPassword = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
    },

    /*
    getCompanies() {
      this.axios
        .post('affiliate/list', { page: 1, per_page: 1000 }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.companies.push({ id: 0, name_comercial: 'Todas' })
            res.data.data.forEach(element => {
              this.companies.push({
                id: element.id,
                name_comercial: element.name_comercial,
              })
            })
          }
        })
    },
    getRoles() {
      this.axios
        .get('role?page=1&per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            console.log(res.data.data)
            this.roles.push({ id: 0, name: 'Todos' })
            res.data.data.forEach(element => {
              if (element.slug !== 'super-admin') {
                this.roles.push({
                  id: element.id,
                  name: element.name,
                })
              }
            })
          }
        })
    },
    */
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
